import { graphql, PageProps } from "gatsby"
import _ from "lodash"
import React from "react"

import { BrandPageContext } from "../interfaces/pageContext"
import CollectionPage from "./CollectionPage"

export type BrandPageProps = PageProps<any, BrandPageContext>

const BrandPage: React.FC<BrandPageProps> = (props) => {
  const { pageContext, data } = props
  const { brand } = pageContext
  const navigation = [
    {
      heading: `Kategorien`,
      links: _.sortBy(
        data.categoryBrandPages.edges.map(({ node }: any) => ({
          to: node.path,
          text: `${brand} ${node.context.category}`,
        })),
        [(link): string => link.text.toLowerCase()]
      ),
    },
  ]
  return (
    <CollectionPage
      name={`${brand}`}
      description={`${brand} Taschen online kaufen bei buybags.de. Entdecke jetzt schwarze, weiße oder braune Handtaschen, Umhängetaschen und Shopper für Damen.`}
      primaryKeyword={`${brand} kaufen`}
      secondaryKeyword={`Taschen Online Shop`}
      navigation={navigation}
      {...props}
    />
  )
}

export default BrandPage

export const query = graphql`
  query BrandPageQuery($brand: String!, $skip: Int!, $limit: Int!) {
    allProductsCsv(filter: { fields: { brand: { eq: $brand } } }, skip: $skip, limit: $limit) {
      edges {
        node {
          fields {
            brand
            colors
            deepLink
            deliveryCost
            deliveryTime
            description
            gtin
            images {
              alt
              src
              title
            }
            material
            name
            price
            priceBefore
            sale
            slug
          }
        }
      }
      totalCount
    }
    categoryBrandPages: allSitePage(
      filter: {
        context: {
          brand: { eq: $brand }
          humanPageNumber: { eq: 1 }
          type: { eq: "CategoryBrandPage" }
        }
      }
      sort: { fields: [context___category], order: ASC }
    ) {
      edges {
        node {
          path
          context {
            category
          }
        }
      }
    }
  }
`
